

@media screen and (max-width: 600px) {
    li {
        font-size: .9rem;
        color: #454343;
    }
    
    .links li:nth-of-type(1) {
        color: #1E1E1E;
        font-weight: bold;
        font-size: 1rem;
    }
    
    .line {
        border: .1px rgba(221, 221, 221, 0.767) solid;
    }
}

@media screen and (min-width: 600px) {
    li {
        font-size: .8rem;
        color: #454343;
    }
    
    .links li:nth-of-type(1) {
        color: #1E1E1E;
        font-weight: bold;
        font-size: 1rem;
    }
    
    .line {
        border: .1px rgba(221, 221, 221, 0.767) solid;
    }
  }