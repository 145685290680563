/* .access {
    border: 1px #358E07 solid;
} */

@media screen and (min-width: 600px) {
  .access {
    border: 1px #358E07 solid;
  }
}

@media screen and (max-width: 600px) {
  .access {
    text-decoration: underline;
  }
}


.book {
    border: 1px #358E07 solid;
}

.book:hover {
    background-color: #349105e1;
  }

hr {
    border: none;
    border-top: 1px dashed #C8C9C4;
    color: #fff;
    background-color: #fff;
    height: 1px;
    width: 50%;
  }

  .vector {
    border: 1px dashed #C8C9C4;
    border-radius: .4rem;
  }